<template>
  <div class="col-10 col-md-6 col-xl-5 mb-3 text-center" >
    <CardPlain v-if="donatingAs" padding="p-2">
      <div class="row mx-0 justify-content-center text-center">
        <div class="col-12 bold green-text-dark mb-2">
          <div class="row mx-0 justify-content-center align-items-center" >
            <div class="col-12 p-0">
              <div class="profile_img m-auto">
                <Image :imageName="imageUrl" width="50" theClass="profile_img" v-if="imageUrl" />
                <IconUser size="sizefull" v-else />
              </div>
            </div>
            <div class="col-12 p-0 mb-1 medium green-text font12 letter_spacing">
              <span v-if="sadaqahUserDonatingType === 'organisation'">ORGANISATION </span>
              <span v-else>DONOR </span>
              ID: {{ donatingAs.displayID }}
            </div>
            <div class="col-12 p-0 green-text bold">
              {{ donatingAs.displayName }}
            </div>
          </div>
        </div>
      </div>
    </CardPlain>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    CardPlain: defineAsyncComponent(() => import('@/components/CardPlain.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue')),
    IconUser: defineAsyncComponent(() => import('@/components/icons/IconUser.vue'))
  },
  name: 'User Display',
  props: ['donatingAs', 'sadaqahUserDonatingType', 'imageUrl']
}
</script>
<style scoped>
 .profile_img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  /* box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%); */
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .profile_img {
    height: 120px;
    width: 120px;
  }
}
</style>
